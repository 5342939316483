<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { headPassengerManager } from '../../js/stores.js';
  import PassengerSummaryPaginated from '../components/PassengerSummaryPaginated.svelte';

  export let currentPage;
  export let moveToPage;
  export let pageSize;
  export let currentPassengerIndex;
  export let disablePaginationForPassportScan;
  export let isGuidedFlow;
  
  const passengers = headPassengerManager.sortPassengers();

  /**
   * PassengersList is a reused pattern for displaying a list of passengers.
   *
   * @example <caption>Example usage.</caption>
   *  <PassengersList />
   */

   const dispatch = createEventDispatcher();
   function skipPassenger() {    
    dispatch('skipPassenger');
  }
</script>

<div class="relative">
  <PassengerSummaryPaginated
    {currentPage}
    {pageSize}
    {passengers}
    {disablePaginationForPassportScan}
    {isGuidedFlow}
    bind:currentPassengerIndex
    on:skipPassenger={skipPassenger}
    bind:moveToPage />
</div>

import { get } from 'svelte/store';
import { resettable } from './extensions/resettable';
import logger from '../logger';
import { safeObjectAttribute, timeoutPromise } from '../utils';
import switchboardClient from '../services/switchboard';
import { currentPassenger, transactionId } from '../stores';
import { baseStationCode } from './config';

const RETRIEVAL_TIMEOUT = 240000; // 4 minutes (240000 miliseconds) to retrieve all boarding passes 

export const reprintBPResponses = resettable([]);

export const bpPassenger = resettable([]);

/**
 * Reprint data operations.
 */
class ReprintBPManager {
  constructor() {
    this.retrievalPromise = null;
  }

  /**
   * Throws an error if any of the BP retrievals failed at switchboard.
   */
  throwAnyFailure() {
    const concatMessage = get(reprintBPResponses).reduce(
      (concatenated, response) => {
        const errorMessage = safeObjectAttribute(
          response,
          'data.rePrintBP.message',
        );
        if (errorMessage) {
          const passenger = response.passenger || {};
          concatenated +=
            `${passenger.firstName} ${passenger.lastName} ` +
            `(${passenger.passengerID}): ${errorMessage}.`;
        }
        return concatenated;
      },
      '',
    );

    if (concatMessage) {
      throw new Error(
        `Error retrieving boarding pass reprint data. ${concatMessage}`,
      );
    }
  }

  /**
   * Whether a reprint has failed for at least one passenger in the booking.
   *
   * @returns {boolean}
   */
  hasAnyFailure() {
    return get(reprintBPResponses).some(
      (response) => response.data.reprintBP.message,
    );
  }
  /**
   * Reset the svelte stores.
   */
  reset() {
    reprintBPResponses.reset();
    this.retrievalPromise = null;
  }

  /**
   * Get retrieval promise.
   *
   * The Promise is rejected in the following cases:
   *  - Retrieval has not yet been initiated.
   *  - RETRIEVAL_TIMEOUT expires since getRetrievalPromise is called.
   *  - Any of the switchboard calls had a failure message.
   *
   * @param {string} booking - The booking.
   * @returns {promise}
   */
  getRetrievalPromise(booking, infants) {
    if (!reprintBPManager.retrievalPromise) {
      logger.warn(
        'getRetrievalPromise() called, ' +
        'but the retrieval had not been initiated.' +
        'Calling reprintBPManagager.performReprint().',
      );
      reprintBPManager.performReprint(booking, infants);
    }

    return timeoutPromise(
      RETRIEVAL_TIMEOUT,
      reprintBPManager.retrievalPromise.then(reprintBPManager.throwAnyFailure),
    );
  }

  /**
   * Receive 'Reprint' data for passengers that were already checked in.
   *
   * Receive the result using getRetrievalPromise().
   *
   * Reprint data need only be requested for parents, not infants.
   *
   * @param {string} booking - The booking.
   */
  performReprint(booking, infants) {
    if (get(reprintBPResponses).length) {
      logger.info('Replacing previous reprint responses.');
      reprintBPResponses.reset();
    }

    bpPassenger.set([]);

    const getBoardingPassDataTasks = (get(booking).passengers || [])
      .concat(get(infants))
      .map((passenger) => {
        const displayedSegments = booking.getDisplayedSegments(
          false,
          null,
          null,
        );

        return displayedSegments.map((segment) => {
          return () => {
            return this.startGettingBoardingPassData(
              booking,
              segment,
              passenger
            );
          };
        });
      });

    const pendingGetBoardingPassDataTask = getBoardingPassDataTasks
      .flat()
      .reduce(
        (accumulator, currentFunc) => accumulator.then(currentFunc),
        Promise.resolve(),
      );

    reprintBPManager.retrievalPromise = pendingGetBoardingPassDataTask;
  }

  startGettingBoardingPassData(booking, segment, passenger) {
    // Note: if the flight is triangular, the segment.arrivalCode will show only the first leg but this value is
    //not used in building the query to amadeus
    logger.info(
      `Request reprintBP. ` +
        `${booking && booking.getPassengerLogMessage(passenger)}.` +
        ` segment: ${segment?.departureCode}-${segment?.arrivalCode}.`,
    );

    const passengerSegment = passenger.segments.filter(
      (x) => x.departureCode === segment.departureCode,
    );

    let passengerListBoardingPass = [];

    passengerListBoardingPass.push({
      firstName: passenger.firstName,
      surname: passenger.lastName,
      customerPrimeId: passenger.passengerID,
      marketingCarrier: segment.marketingAirlineCode,
      flightNumber: segment.flightNumber,
      departureDate: segment.standardDepartureDateTime,
      boardPoint: segment.departureCode,
      productPrimeId: passengerSegment[0]?.passengerDID
    });
  
    const currentBooking = get(booking);
    let getBoardingPassXMLDataInput = {
      primeLegMarketingCarrier: currentBooking.airlineCode,
      primeLegFlightNumber: currentBooking.flightNumber,
      primeLegDepartureDate: currentBooking.segments[0].standardDepartureDateTime,
      primeLegBoardPoint: currentBooking.originCode,
      primeLegOffPoint: currentBooking.destinationCode,
      passengerListBoardingPass: passengerListBoardingPass,
      baseStation: baseStationCode,
      transactionId: get(transactionId)
    }

    return switchboardClient
      .printBoardingPass(getBoardingPassXMLDataInput)
      .then((response) => {
        logger.info(`Received reprintBP response. `);

        const boardingPassXmlDataResp = response?.data?.boardingPassXmlData;
        const isGetBoardingPassXMLDataSuccessful = boardingPassXmlDataResp && boardingPassXmlDataResp.isGetSuccessful;
        if (!isGetBoardingPassXMLDataSuccessful) {          
          
          let reason = '';
          
          boardingPassXmlDataResp?.errorMessages?.forEach(e => {
            reason = reason + e?.message; 
          });
          
          
          const errorMsg = 'Failed to retrieve boardingPassXmlData.';
          logger.error(errorMsg,
            `Reason: ${reason}.`,
            `Passenger: ${passenger?.firstName} ${passenger?.lastName}.`,
            `Segment: ${segment?.departureCode}-${segment?.arrivalCode}.`);
          throw new Error(errorMsg);
        }

        response.segment = segment;
        response.passenger = passenger;
  
        if (response && response.data && response.data.boardingPassXmlData && response.data.boardingPassXmlData.rePrintBPResponseList
          && response.data.boardingPassXmlData.rePrintBPResponseList.length > 0 &&
          response.data.boardingPassXmlData.rePrintBPResponseList[0].itineraryList &&
          response.data.boardingPassXmlData.rePrintBPResponseList[0].itineraryList.length > 0) {
          response.data.boardingPassXmlData.rePrintBPResponseList[0].itineraryList.forEach((itinerary) => {
            if (segment.flightNumber === itinerary.flight) {
              response.segment.departureGate = itinerary.departureGate;
              segment.departureGate = itinerary.departureGate;
              
              itinerary.passengerDetails.forEach((p) => {
                response.segment.seatNumber = p.seat;
                segment.seatNumber = p.seat;
                segment.passengerType = p.passengerType;

                if(p.frequentFlyerDetails != null && p.frequentFlyerDetails.tierDescription != null)
                {
                  // EG Silver -> SILVER, in line with CM BP samples
                  segment.paxFFTierDescription = p.frequentFlyerDetails.tierDescription.toUpperCase()                  
                }
                else
                {
                  segment.paxFFTierDescription = ""
                }

                // 1. Do not allow more than 4 SSR codes
                // 2. Join with "/" delimiter to match CM behaviour
                let truncatingLength = Math.min(p.sSRCodeList.length, 4);

                p.sSRCodeList.length = truncatingLength
                segment.ssrCodeList = p.sSRCodeList.join("/")
  
                if (p?.travelDocDataList && p?.travelDocDataList?.length > 0) {
                  p?.travelDocDataList?.forEach((t) => {
                    if (t && t?.xMLDigitalSignatureData) {
                      response.segment.zone = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.groupZone;
                      segment.zone = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.groupZone;
                      segment.sequenceNumber = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.checkInSequence;
                      response.segment.barcode = t.xMLDigitalSignatureData.barcodeInfo.barcode;
                      segment.barcode = t.xMLDigitalSignatureData.barcodeInfo.barcode;
                      segment.boardingTime = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.boardingTime;
                      segment.cabinText = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.cabinText;
                      segment.originCity = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.originCity;
                      segment.destinationCity = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.destinationCity;
                      segment.departureDate = itinerary.departureDate;
                      segment.arrivalDate = itinerary.arrivalDate;
                      segment.departureTime = itinerary.departureTime;
                      segment.arrivalTime = itinerary.arrivalTime;                      
                      segment.lounge = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.lounge;
                      segment.fareFamily = t.xMLDigitalSignatureData?.boardingPassItineraryDetail?.fareFamily;
                    }
                  });
                }
              });
            }
          });
        }
  
        let bpArray = get(bpPassenger);
        
        if (!bpArray) {
          bpArray = [];
        }
        
        // booking reference for each passenger needs to be added
        bpArray.push({
          bookingReference: passenger.bookingReference,
          passengerID: passenger.passengerID,
          passengerDID: segment.passengerDID,
          passengerFFTierName: segment.paxFFTierDescription,
          zone: segment.zone,
          gateNumber: segment.departureGate,
          seatNumber: segment.seatNumber,
          barcode: segment.barcode,
          sequenceNumber: segment.sequenceNumber,
          passengerType: segment.passengerType,
          boardingTime: segment.boardingTime,
          cabinText: segment.cabinText,
          originCity: segment.originCity,
          destinationCity: segment.destinationCity,
          departureDate: segment.departureDate,
          arrivalDate: segment.arrivalDate,
          departureTime: segment.departureTime,
          arrivalTime: segment.arrivalTime,
          ssrCodeList: segment.ssrCodeList,
          lounge: segment.lounge,
          fareFamily: segment.fareFamily
        });

        bpPassenger.set(bpArray);
        reprintBPResponses.set([...get(reprintBPResponses), response]);
      });
  }
}

export const reprintBPManager = new ReprintBPManager();

<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { hotkey } from '../../../js/actions';

  export let currentPage;
  export let pageNumber;
  export let disablePaginationForPassportScan;
  export let isGuidedFlow;

  const dispatch = createEventDispatcher();
  const i18nKey = `app.no${pageNumber}`;

  let isActive = false;
  let isPreviousPage = false;
  $: isActive = pageNumber === currentPage;
  $: isPreviousPage = pageNumber < currentPage;
</script>

<style>
  button {
    @apply bg-transparent
      border-1
      border-gold-100
      flex
      h-8
      items-center
      justify-center
      m-2
      rounded-full
      text-gold-100
      w-8;
  }

  .isActive {
    @apply bg-gold-100
      text-white;
  }

  button.isPreviousPage {
    color: grey;
  }
</style>

<button
  class:isActive
  class:isPreviousPage
  disabled={isGuidedFlow && disablePaginationForPassportScan && !isActive}
  role="button"
  use:hotkey={pageNumber}
  on:click={() => {
    dispatch('setPage', { pageNumber });
  }}
>
  {$_(i18nKey)}
</button>

<script>
  import { createEventDispatcher } from 'svelte';
  import { paginate } from 'svelte-paginate/src';
  import logger from '../../js/logger';

  import Nav from './PassengerPagination/Nav.svelte';
  import PassengerSummaryHeadings from '../components/PassengerSummaryHeadings.svelte';
  import PassengerSummaryItem from './PassengerSummaryItem.svelte';

  export let currentPage = 1;
  export let pageSize = 4;
  export let passengers = [];
  export let currentPassengerIndex;
  export let showCheckCircle = true; // this is a special case of return to bag drop passenger scan
  export let disablePaginationForPassportScan;
  export let isGuidedFlow;

  export const moveToPage = (pageNumber) => {
    if (currentPage !== pageNumber) {
      logger.info(`Moving to page ${pageNumber}.`);
      currentPage = pageNumber;
    }
  };

  let numberOfPassengers = passengers.length;

  $: paginated = paginate({
    items: passengers,
    pageSize,
    currentPage,
  });

  function setPageHandler(event) {
    currentPage = event.detail.pageNumber;
  }

  const dispatch = createEventDispatcher();
  function skipPassenger() {
    dispatch('skipPassenger');
  }
</script>

<PassengerSummaryHeadings />
{#each paginated as passenger}
  <PassengerSummaryItem
    {passenger}
    {passengers}
    bind:currentPassengerIndex
    on:skipPassenger={skipPassenger}
    {showCheckCircle}
  />
{/each}

<Nav
  totalItems={numberOfPassengers}
  {currentPage}
  {pageSize}
  {disablePaginationForPassportScan}
  {isGuidedFlow}
  on:setPage={setPageHandler}
/>
